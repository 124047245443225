import React from 'react';

function Error(props){

    return(
        <div className="error">
            <article className="message is-danger">
            <div className="message-header">
                <p>Oops!</p>
            </div>
                <div className="message-body">
                 {props.value}
                </div>
                </article>
        </div>
    );
}
export default Error;