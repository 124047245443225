import React, {Component, Fragment} from 'react';

import { scapi } from '../utils';
class AddOrEditQuizPrize extends Component{
    constructor(props){
        super(props)
        this.state = {
            prize: props.location.state.data
        }
    }
    handleChange = (e) => {
        let {name, value} = e.target;
        let prizeData = {...this.state.prize};
        prizeData[name] = value;
        this.setState({
            prize: prizeData
        })
    }
    handleSubmit = () => {
        let { prize } = this.state;
        let payload = {
            identifier: prize.identifier,
            description: prize.description,
            img_link: prize.img_link
        }
        if(prize.id) payload['id'] = prize.id
        scapi('POST', 'livequiz/api/v1/cms/createOrEditQuizPrize', payload)
        .then(result => {
            this.props.history.push("/prizes");
        })
    }
    render(){
        const { prize } = this.state;
        return(
            <Fragment>
                <div className="title">Add/Edit Quiz Prize</div>
                <div className="field">
                    <label className="label">Identifier</label>
                    <input className="input" name="identifier"  value={prize.identifier} onChange={this.handleChange}></input>
                </div>
                <div className="field">
                    <label className="label">Description</label>
                    <textarea className="textarea is-info" name="description"  value={prize.description} onChange={this.handleChange}></textarea>
                </div>
                <div className="field">
                    <label className="label">Image Link</label> 
                    <input className="input" name="img_link"  value={prize.img_link} onChange={this.handleChange}></input>
                </div>
                <button className="button is-info" onClick={this.handleSubmit}>Submit</button>
            </Fragment>
        )
    }
}
export default AddOrEditQuizPrize;