import React from 'react';
import {NavLink} from 'react-router-dom';
import './sidebar.css';
// import Cookies from 'js-cookie';

function Sidebar(){
    // const name = Cookies.get('name');
    const token = localStorage.getItem('token');
    if(token){
    return(
        <div className="sidebar">
        <nav className="menu">
            <ul className="menu-list">
                <li>
                    <NavLink activeClassName='is-active' to="/login"><span>Login</span></NavLink>
                </li>
                <li>
                    <NavLink activeClassName='is-active' to="/livequiz"><span>Live Quiz</span></NavLink>
                </li>
                <li>
                    <NavLink activeClassName='is-active' to="/newQuiz"><span>New Quiz</span></NavLink>
                </li>
                <li>
                    <NavLink activeClassName='is-active' to="/allNewQuizzes"><span>All New Quizzes</span></NavLink>
                </li>
                <li>
                    <NavLink activeClassName='is-active' to="/uploadQuestion"><span>Upload Questions</span></NavLink>
                </li>
                <li>
                    <NavLink activeClassName='is-active' to="/allQuestions"><span>All Questions</span></NavLink>
                </li>
                <li>
                    <NavLink activeClassName='is-active' to="/rules"><span>Quiz Rules</span></NavLink>
                </li>
                <li>
                    <NavLink activeClassName='is-active' to="/prizes"><span>Quiz Prizes</span></NavLink>
                </li>
            </ul>
        </nav>
        </div>
    );
    }
    else {
        return (
            <h3> </h3>
        )
    }
}
export default Sidebar;