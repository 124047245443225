import React, {Component, Fragment} from 'react';
import './css/quizRules.css';
import { scapi } from '../utils';

class Rules extends Component{
    constructor(props){
        super(props)
        this.state = {
            rule: {
                identifier: null,
                description: null,
                // terms_and_condition: ''
            },
            rules: []
        }
    }
    componentDidMount(){
        scapi('GET', 'livequiz/api/v1/cms/allRules')
        .then(result => {
            this.setState({ rules: result})
        })
    }
    handleRule = (data) =>{
        this.props.history.push("/addOrEditQuizRule", {data});
    }
    render(){
        return(
            <Fragment>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="title">Quiz Rules</div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <button className="button is-info" onClick={()=> this.handleRule(this.state.rule)}>Create Rule</button>
                        </div>
                    </div>
                </div>

               <section>
                    <table className="table is-bordered is-fullwidth">
                        <thead>
                            <tr>
                                <th>Identifier</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.rules.map((rule) => {
                            return <tr onClick={() => this.handleRule(rule)}>
                                        <td>{rule.identifier}</td>
                                        <td>{rule.description}</td>
                                    </tr>
                        })
                              
                         }
                        </tbody>
                    </table>
                </section>  
            </Fragment>
        )
    }
}
export default Rules;