import React, {Component, Fragment} from 'react';

import { scapi } from '../utils';

class Prizes extends Component{
    constructor(props){
        super(props)
        this.state = {
            prize: {
                identifier: null,
                description: null,
                img_link: null
            },
            prizes: [],
            isDelete: false
        }
    }
    componentDidMount(){
        scapi('GET', 'livequiz/api/v1/cms/allPrizes')
        .then(result => {
            this.setState({ prizes: result})
        })
    }
    handlePrize = (e, data) =>{
        e.stopPropagation();
        // if(!this.state.isDelete){
            console.log('create----', this.state.isDelete )
        this.props.history.push("/addOrEditQuizPrize", {data});
        // }
    }
    handleDelete = (e,id) => {
        e.stopPropagation();
        scapi('DELETE', `livequiz/api/v1/cms/deletePrize?id=${id}`)
        .then(result => {
            this.props.history.go(0);
        })
    }
    render(){
        return(
            <Fragment>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="title">Quiz Prizes</div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <button className="button is-info" onClick={(e)=> this.handlePrize(e, this.state.prize)}>Create Prize</button>
                        </div>
                    </div>
                </div>

               <section>
                    <table className="table is-bordered is-fullwidth">
                        <thead>
                            <tr>
                                <th>Identifier</th>
                                <th>Description</th>
                                <th>Image Link</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.prizes.map((prize) => {
                            return <tr onClick={(e) => this.handlePrize(e, prize)} key={prize.id}>
                                        <td>{prize.identifier}</td>
                                        <td>{prize.description}</td>
                                        <td>{prize.img_link}</td>
                                        <td><button className="button is-danger" onClick={(e) => this.handleDelete(e, prize.id)}>Delete</button></td>
                                    </tr>
                        })
                              
                         }
                        </tbody>
                    </table>
                </section>  
            </Fragment>
        )
    }
}
export default Prizes;