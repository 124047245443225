import React from 'react';
import { scapiForm } from '../utils';
import Error from '../component/Error';
class UploadQuestion extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            file: null,
            error: null,
            message: null
        }
    }
    handleChange = (e) =>{
        console.log('file---->', e.target.files[0])
        this.setState({
            file: e.target.files[0]
        })
    }
    handleUpload = ()=>{
        if(!this.state.error){
                let file = this.state.file
                scapiForm('/onechamp/questionBank', file)
                .then(result=>{
                    console.log('result---->', result)
                    if(result){
                        this.setState({message: 'You have successfully uploaded questions'});
                    }
                }).catch(err=>{
                    console.log('err----->',err);
                    this.setState({
                        error: 'Something went wrong!!!'
                    })
                })
        }
    }
    render(){
        let {error, message} = this.state;
        return(
            <div>
                <div className="title">Upload Question</div>
                {this.state.message? 
                    <div>
                        <article className="message is-success">
                            <div className="message-header">
                                <p>Success</p>
                                <button className="delete" aria-label="delete" onClick={()=> this.setState({message: null})}></button>
                            </div>
                            <div className="message-body">
                            {message}
                            </div>
                        </article>
                    </div>
                    :
                    <div></div>
                }
                {error? (<Error value={error}/>)
                    :
                    null
                }
                <input className="input" type="file" onChange={this.handleChange}></input>
                <br></br>
                <button className="button is-info" onClick={this.handleUpload}>Upload</button>
            </div>
        )
    }
}
export default UploadQuestion;