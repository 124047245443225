import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Sidebar from '../component/Sidebar';
import Login from '../view/Login';
import LiveQuizzes from '../view/LiveQuizzes';
import AddOrEditLiveQuiz from '../view/AddOrEditLiveQuiz';
import QuizRules from '../view/QuizRules';
import AddOrEditQuizRule from '../view/AddOrEditQuizRule';
import QuizPrizes from '../view/QuizPrizes';
import AddOrEditQuizPrize from '../view/AddOrEditQuizPrize';
import NewQuiz from '../view/NewQuiz';
import UploadQuestion from '../view/UploadQuestion';
import AllNewQuizzes from '../view/AllNewQuizzes';
import AllQuestions from '../view/AllQuestions';
import EditNewQuiz from '../view/EditNewQuiz';

function Routers(){
    // const name = Cookies.get('name');
    const token = localStorage.getItem('token');
    return(
        <Router>
            <div>
                <Sidebar />
                <Switch>
                    <Route path="/" exact render={() => (<Redirect to="/login" />)} />
                    <Route path="/login" component={Login} />
                    {token?(
                        <div>
                    <Route path="/livequiz" exact component={LiveQuizzes} />
                    <Route path="/newQuiz" component={NewQuiz} />
                    <Route path="/allNewQuizzes" component={AllNewQuizzes} />
                    <Route path="/uploadQuestion" component={UploadQuestion} />
                    <Route path="/allQuestions" component={AllQuestions} />
                    <Route path="/addOrEditLiveQuiz" component={AddOrEditLiveQuiz} />
                    <Route path="/rules" component={QuizRules} />
                    <Route path="/addOrEditQuizRule" component={AddOrEditQuizRule} />
                    <Route path="/prizes" component={QuizPrizes} />
                    <Route path="/addOrEditQuizPrize" component={AddOrEditQuizPrize} />
                    <Route path="/editNewQuiz" component={EditNewQuiz} />
                    </div>
                    )
                    :
                    (<h3></h3>)
                    }
                </Switch>
            </div>
        </Router>
    );
}
export default Routers;