import React, {Component} from 'react';
import { scapi } from '../utils';
import './css/allQuestions.css'

class AllQuestions extends Component{
    constructor(props){
        super(props)
        this.state =  {
            all_questions: [],
            current_page: 1,
            nextDisable: true,
            prevDisable: true
        }
    }
    componentDidMount(){
        let {current_page} = this.state;
        this.fetchQuestions(current_page);
    }
    fetchQuestions = (page) =>{
        scapi('get', `onechamp/fetchQuestionBank?pageNo=${page}`)
        .then(result=>{
            console.log('all quiz--->>>>', result);
            this.setState({all_questions: result});
            if(result.length<20){
                this.setState({nextDisable: true});
            }
            else this.setState({nextDisable: false});
        })
    }
    handleNextPage = async() =>{
        let {current_page, prevDisable} = this.state;
        if(current_page >= 1 && prevDisable) await this.setState({prevDisable: false})
        let page = current_page + 1;
        await this.setState({current_page: page})
        await this.fetchQuestions(page);
    }
    handlePrevPage = async() =>{
        let {current_page, all_questions} = this.state;
        if(current_page === 1){
            await this.setState({prevDisable: true});
        }
        else{
            let page = current_page - 1;
            await this.setState({current_page: page})
            await this.fetchQuestions(page);
        }
        if(all_questions.length === 20) await this.setState({nextDisable: false});
        
    }
    render(){
        const {all_questions, nextDisable, prevDisable, current_page} = this.state;
        return(
            <React.Fragment>
                <section>
                    <div className="lavel">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="title">All Questions</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="question-table">
                    <table className="table is-bordered is-fullwidth">
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Topic</th>
                                <th>Sub Topic</th>
                                <th>Disabled?</th>
                                <th>Question Format</th>
                                <th>Difficulty</th>
                                <th>Question Image</th>
                                <th>Option 1</th>
                                <th>Option 2</th>
                                <th>Option 3</th>
                                <th>Option 4</th>
                                <th>Question Answer</th>
                            </tr>
                        </thead>
                        <tbody>
                        {all_questions.map((quiz, i) => {
                            return <tr key={i}>
                                        <td>{quiz.question_text}</td>
                                        <td>{quiz.topic}</td>
                                        <td>{quiz.sub_topic}</td>
                                        <td>{quiz.disabled? "YES" : "NO"}</td>
                                        <td>{quiz.question_format}</td>
                                        <td>{quiz.difficulty}</td>
                                        <td>{quiz.question_image}</td>
                                        <td>{quiz.question_option_1}</td>
                                        <td>{quiz.question_option_2}</td>
                                        <td>{quiz.question_option_3}</td>
                                        <td>{quiz.question_option_4}</td>
                                        <td>{quiz.question_answer}</td>
                                    </tr>
                        })
                              
                         }
                        </tbody>
                    </table>
                    </div>
                    <div className="page-button-section">
                        <button className="button is-info" disabled={prevDisable} onClick={this.handlePrevPage}>Prev</button>
                        <button className="button is-info" disabled={nextDisable} onClick={this.handleNextPage}>Next</button>
                    </div>
                    <div className="page">Current Page: {current_page}</div>
                </section>  
            </React.Fragment> 
        );
    }
}
export default AllQuestions;