import axios from 'axios';
export let modImgUrl = function(url, insert) {
  //modify es3
  let urlParts = url.split('/');
  let ind = urlParts.findIndex(d => d.includes('es3'));
  if (ind > 0) {
    urlParts[ind] = insert;
    url = urlParts.join('/');
  }

  //encodeURI
  url = encodeURI(url);
  return url;
};
export let scapi = function(method, url, data) {
  let apiLink
  return new Promise((resolve, reject) => {
      // apiLink= "http://localhost:8181/"
      apiLink = "https://onequizserver.azurewebsites.net/"

    //request
    axios({
      method,
      url,
      data,
      baseURL: apiLink,
      headers: Object.assign({}, { Authorization: localStorage.getItem('token') }),
      timeout: 1000000
    })
      .then(response => {
        if (response.data.hasOwnProperty('data')) {
          resolve(response.data.data);
        } else {
          resolve(response.data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export let scapiForm = function( url, data) {
  let apiLink;
  return new Promise((resolve, reject) => {
    apiLink = "https://onequizserver.azurewebsites.net/"
    const fd = new FormData();
    fd.append('file', data);

    let options = {
      method: 'POST',
      url,
      data: fd,
      baseURL: apiLink,
      headers:Object.assign({}, { Authorization: localStorage.getItem('token')},{'Content-Type': `multipart/form-data; boundary=${fd._boundary}`}),
      timeout: 1000000
    };

    axios(options)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

