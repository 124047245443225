import React, {Component} from 'react';
import { scapi } from '../utils';
import moment from 'moment';
// const jsonexport = require('jsonexport');
class AllNewQuizzes extends Component{
    constructor(props){
        super(props)
        this.state =  {
            live_quiz: {
                quiz_name: "",
                quiz_description: null,
                quiz_rules_id: null,
                quiz_prizes_id: [],
                start_time: null,
                questions: [{
                    question_text: null,
                    question_context: null,
                    question_option_1: null,
                    question_option_2: null,
                    question_option_3: null,
                    question_option_4: null,
                    question_offset: null,
                    option_offset: null,
                    question_answer: null,
                    question_points: 10,
                    question_neg_points: 0
                }],
                video_link: null
            },
            all_quiz: [],
            Tabs: [
                {
                    name: 'Upcoming',
                    id: 1
                },
                {
                    name: 'Live',
                    id: 2
                },
                {
                    name: 'Completed',
                    id: 3
                }
            ],
            tabName: 'Upcoming',
            currTabId: 1,
            quizzes: []
        }
    }
    componentDidMount(){
        scapi('get', 'onechamp/fetchActiveQuiz')
        .then(result=>{
            console.log('all quiz--->>>>', result);
            this.setState({
                quizzes: result && result.quiz? result.quiz : []
            })
            // result = result.filter(item=>{
            //     item.start_time =moment(item.start_time).format('YYYY-MM-DD HH:mm')
            //     if(item.start_time>moment(new Date()).format('YYYY-MM-DD HH:mm')){
            //         return item;
            //     }
            // })
            this.setState({all_quiz: result && result.quiz? result.quiz : []})
        })  
    }
    handleEditQuiz = (data) => {
        this.props.history.push("/editNewQuiz", {data});
    }
    render(){
        const {all_quiz} = this.state;
        return(
            <React.Fragment>
                <section>
                    <div className="lavel">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="title">New Live Quizzes</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <table className="table is-bordered is-fullwidth">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Quiz Start Time</th>
                                <th>Quiz End Time</th>
                                <th>Question Count</th>
                            </tr>
                        </thead>
                        <tbody>
                        {all_quiz && all_quiz.map((quiz, i) => {
                            return <tr key={i} onClick={() => this.handleEditQuiz(quiz)}>
                                        <td>{quiz.id}</td>
                                        <td>{quiz.start_time}</td>
                                        <td>{quiz.end_time}</td>
                                        <td>{quiz.question_count}</td>
                                    </tr>
                        })
                              
                         }
                        </tbody>
                    </table>
                </section>  
            </React.Fragment> 
        );
    }
}
export default AllNewQuizzes;