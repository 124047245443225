import React, {Component, Fragment} from 'react';
import moment from 'moment';
import './css/newQuiz.css'
import { scapi } from '../utils';
import Error from '../component/Error';

class EditNewQuiz extends Component{
    constructor(props){
        super(props)
        this.state = {
            quiz: props.location.state.data,
            message: null,
            error: null
        }
    }
    componentDidMount(){
        let updatedQuiz = this.state.quiz;
        updatedQuiz.start_time = moment(this.props.location.state.data.start_time).format('YYYY-MM-DDTHH:mm');
        updatedQuiz.end_time = moment(this.props.location.state.data.end_time).format('YYYY-MM-DDTHH:mm');
        this.setState({quiz: updatedQuiz});
    }
    handleChange = (e) => {
        e.preventDefault()
        let {name, value} = e.target
        console.log('value--', name, value);
        let quizData = { ...this.state.quiz}
        quizData[name] = value;
        this.setState({
            quiz: quizData,
            error: null
        })
    }
    handleSubmit = () => {
        this.setState({error: null})
        let {quiz} = this.state;
        let payload = {
            end_time: moment(quiz.end_time, 'YYYY-MM-DDTHH:mm').toISOString(),
            start_time:  moment(quiz.start_time, 'YYYY-MM-DDTHH:mm').toISOString(),
            id: +quiz.id,
            banner_url: quiz.banner_url
        }
        if(!this.state.error){
                scapi('POST', 'onechamp/updateQuiz', payload)
                .then(result=>{
                    if(result){
                        this.setState({message: 'You have successfully updated quiz'});
                    }
                })
        }
    }
    render(){
        const {quiz, error} = this.state;
        return(
            <Fragment>
                <div className="lavel">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="title">Edit New Quiz</div>
                        </div>
                    </div>
                </div>
                {this.state.message? 
                    <div>
                        <article className="message is-success">
                            <div className="message-header">
                                <p>Success</p>
                                <button className="delete" aria-label="delete" onClick={()=> this.setState({message: null})}></button>
                            </div>
                            <div className="message-body">
                            {this.state.message}
                            </div>
                        </article>
                    </div>
                    :
                    <div></div>
                }
                <section>
                    <div className="Field">
                        <label className="label">Start Time</label>
                        <input className="input" name="start_time" value={quiz.start_time} type="datetime-local" onChange={this.handleChange}></input>
                    </div>
                    <div className="Field">
                        <label className="label">End Time</label>
                        <input className="input" name="end_time" value={quiz.end_time} type="datetime-local" onChange={this.handleChange}></input>
                    </div>
                    <div className="Field">
                        <label className="label">Banner Url</label>
                        <input className="input" name="banner_url" value={quiz.banner_url} type="text" onChange={this.handleChange}></input>
                    </div>
                    <button className="button is-info" onClick={this.handleSubmit}>Submit</button>
                </section>
                {error? (<Error value={error}/>)
                    :
                    null
                }
            </Fragment>
        );
    }
}
export default EditNewQuiz;