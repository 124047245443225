import React, {Component, Fragment} from 'react';
import moment from 'moment';
import './css/newQuiz.css'
import { scapi } from '../utils';
import Error from '../component/Error';

class NewQuiz extends Component{
    constructor(props){
        super(props)
        this.state =  {
            quiz: {
                start_time: null,
                end_time: null,
                question_count: null,
                question_format: [
                    {
                        count: null,
                        topic: null,
                        subtopic: null,
                        difficulty: null,
                        question_format: null
                    }
                ]
            },
            format_obj: {
                count: null,
                topic: null,
                subtopic: null,
                difficulty: null,
                question_format: null
            },
            difficulty_arr: [
                {
                    id: 1,
                    name: 'Easy',
                    short_name: 'easy'
                },
                {
                    id: 2,
                    name: 'Medium',
                    short_name: 'medium'
                },
                {
                    id: 3,
                    name: 'Hard',
                    short_name: 'hard'
                }
            ],
            question_format_arr: [
                {
                    id: 1,
                    name: 'Image',
                    short_name: 'image'
                },
                {
                    id: 2,
                    name: 'Regular',
                    short_name: 'regular'
                }
            ],
            message: null,
            error: null

        }
    }
   async componentDidMount(){

        
    }
    handleChange = (e) => {
        e.preventDefault()
        let {name, value} = e.target
        console.log('value--', name, value);
        let quizData = { ...this.state.quiz}
        quizData[name] = value;
        this.setState({
            quiz: quizData,
            error: null
        })
        console.log('quiz state---', this.state.quiz);
    }
    handleSubmit = () => {
        this.setState({error: null})
        let {quiz} = this.state;
        quiz.question_format.forEach(item =>{
            item.count = parseInt(item.count);
        })
        let question_length = quiz.question_format.length;
        if(!quiz.question_format[question_length-1].count) return this.setState({error: `Please enter the count in format ${question_length}`});
        if(!quiz.question_format[question_length-1].difficulty) return this.setState({error: `Please enter the difficulty in format ${question_length}`});
        if(!quiz.start_time) return this.setState({error: "Please enter the quiz start time"})
        if(!quiz.end_time) return this.setState({error: "Please enter the quiz end time"})
        if(!quiz.question_count) return this.setState({error: "Please enter the question count"})
        let payload = {
            end_time: moment(quiz.end_time, 'YYYY-MM-DDTHH:mm').toISOString(),
            start_time:  moment(quiz.start_time, 'YYYY-MM-DDTHH:mm').toISOString(),
            question_format: quiz.question_format,
            question_count: +quiz.question_count
        }
        console.log('payload----', payload);
        if(!this.state.error){
                scapi('POST', 'onechamp/createQuiz', payload)
                .then(result=>{
                    if(result){
                        this.setState({message: 'You have successfully created quiz'});
                    }
                })
        }
    }
    handleQuestionChange = (e, i) =>{
        let {name, value} = e.target;
        let quizData = {...this.state.quiz}
        quizData.question_format[i][name] = value
        this.setState({quiz:quizData, error: null});
    }
    handleQuestion = () => {
        let quizData = {...this.state.quiz};
        let questionFormatData = {...this.state.format_obj};
        let length = quizData.question_format.length;
        if(length === 0 || (quizData.question_format[length-1].count && 
           quizData.question_format[length-1].difficulty
           )){
                quizData.question_format.push(this.state.format_obj);
                this.setState({quiz:quizData});
                questionFormatData.count = null;
                questionFormatData.topic = null;
                questionFormatData.subtopic = null;
                questionFormatData.difficulty = null;
                questionFormatData.question_format = null;
                this.setState({
                    format_obj: questionFormatData
                })
            }
            else this.setState({error: `Please enter all mandatory fields in format ${length}`});
    }
    handleDelete = (e, index) =>{
        e.preventDefault();
        console.log('index----', index);
        let quizData = {...this.state.quiz};
        if(quizData.question_format.length>1){ 
            quizData.question_format.splice(index, 1);
            this.setState({
                quiz: quizData
            })
        }
    }
    render(){
        const {quiz, difficulty_arr, question_format_arr, error } = this.state
        const difficulties = difficulty_arr.map(diff => {
            return <option key={diff.id} value={diff.short_name}>{diff.name}</option>
        })
        const formatArr = question_format_arr.map(item => {
            return <option key={item.id} value={item.short_name}>{item.name}</option>
        })
        return(
            <Fragment>
                <div className="lavel">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="title">Create New Quiz</div>
                        </div>
                    </div>
                </div>
                {this.state.message? 
                    <div>
                        <article className="message is-success">
                            <div className="message-header">
                                <p>Success</p>
                                <button className="delete" aria-label="delete" onClick={()=> this.setState({message: null})}></button>
                            </div>
                            <div className="message-body">
                            {this.state.message}
                            </div>
                        </article>
                    </div>
                    :
                    <div></div>
                }
                <section>
                    <div className="Field">
                        <label className="label">Start Time<span className="required-field">*</span></label>
                        <input className="input" name="start_time" value={quiz.start_time} type="datetime-local" onChange={this.handleChange}></input>
                    </div>
                    <div className="Field">
                        <label className="label">End Time</label>
                        <input className="input" name="end_time" value={quiz.end_time} type="datetime-local" onChange={this.handleChange}></input>
                    </div>
                    <div className="Field">
                        <label className="label">Question Count<span className="required-field">*</span></label>
                        <input className="input" name="question_count" value={quiz.question_count} type="number" onChange={this.handleChange}></input>
                    </div>
                    <div>
                        {this.state.quiz.question_format.map((question, i)=>{
                            return <div key={i}>
                                    <p className="label">Format {i+1}</p>
                                    <p className="handle-delete" onClick={e => this.handleDelete(e, i)}><span className="question-delete">X</span></p>
                                    <div className="question">
                                        <div className="field">
                                            <label className="label">Count<span className="required-field">*</span></label>
                                            <input className="input" name="count" type="number" value={question.count} onChange={e => this.handleQuestionChange(e, i)}></input>
                                        </div>
                                        <div className="field">
                                            <label className="label">Topic</label>
                                            <input className="input" name="topic" type="text" value={question.topic} onChange={e => this.handleQuestionChange(e, i)}></input>
                                        </div>
                                        <div className="field">
                                            <label className="label">Sub Topic</label>
                                            <input className="input" name="subtopic" type="text" value={question.subtopic} onChange={e => this.handleQuestionChange(e, i)}></input>
                                        </div>
                                        <div className="field">
                                            <label className="label">Difficulty<span className="required-field">*</span></label>
                                            <div className="control">
                                                <div className="select is-primary">
                                                    <select name="difficulty" value={question.difficulty} onChange={e => this.handleQuestionChange(e, i)}>
                                                        <option selected disabled>Select Difficulty</option>
                                                        {difficulties}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label">Question Format</label>
                                            <div className="control">
                                                <div className="select is-primary">
                                                    <select name="question_format" value={question.question_format} onChange={e => this.handleQuestionChange(e, i)}>
                                                        <option selected disabled>Select Question Format</option>
                                                        {formatArr}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        })}
                        <button className="button is-medium" onClick={this.handleQuestion}>+</button>
                    </div>
                    <button className="button is-info" onClick={this.handleSubmit}>Submit</button>
                </section>
                {error? (<Error value={error}/>)
                    :
                    null
                }
            </Fragment>
        );
    }
}
export default NewQuiz;