import React, {Component} from 'react';
import { scapi } from '../utils';
import moment from 'moment';
const jsonexport = require('jsonexport');
class LiveQuizzes extends Component{
    constructor(props){
        super(props)
        this.state =  {
            live_quiz: {
                quiz_name: "",
                quiz_description: null,
                quiz_rules_id: null,
                quiz_prizes_id: [],
                start_time: null,
                questions: [{
                    question_text: null,
                    question_context: null,
                    question_option_1: null,
                    question_option_2: null,
                    question_option_3: null,
                    question_option_4: null,
                    question_offset: null,
                    option_offset: null,
                    question_answer: null,
                    question_points: 10,
                    question_neg_points: 0
                }],
                video_link: null
            },
            all_quiz: [],
            Tabs: [
                {
                    name: 'Upcoming',
                    id: 1
                },
                {
                    name: 'Live',
                    id: 2
                },
                {
                    name: 'Completed',
                    id: 3
                }
            ],
            tabName: 'Upcoming',
            currTabId: 1,
            quizzes: []
        }
    }
    componentDidMount(){
        scapi('get', 'livequiz/api/v1/cms/allQuiz')
        .then(result=>{
            console.log('all quiz--->>>>', result);
            this.setState({
                quizzes: result
            })
            result = result.filter(item=>{
                item.start_time =moment(item.start_time).format('YYYY-MM-DD HH:mm')
                if(item.start_time>moment(new Date()).format('YYYY-MM-DD HH:mm')){
                    return item;
                }
            })
            this.setState({all_quiz:result})
        })  
    }
    handleCreateQuiz = (data) =>{
        console.log('new quiz----')
        if(data.id){
            scapi('GET', `livequiz/api/v1/cms/getQuiz?live_quiz_id=${parseInt(data.id)}`)
            .then(result=>{
                console.log('quiz--->>>', result);
                this.props.history.push("/addOrEditLiveQuiz", {data: result});
            })
        }
        else this.props.history.push("/addOrEditLiveQuiz", {data});
    }
    handleQuizDelete = (e,id) => {
        e.stopPropagation();
        // scapi('DELETE', `livequiz/api/v1/cms/deleteQuiz?id=${id}`)
        // .then(result=>{
        //     console.log(result);
        //     this.props.history.go(0);
        // }) 
    }
    updateTab=(tab)=>{
        console.log(tab.name)
    this.setState({tabName: tab.name},()=>{
        if(this.state.tabName === 'Live') {
            let quiz = this.state.quizzes.filter(item=>{
                item.start_time =moment(item.start_time).format('YYYY-MM-DD HH:mm')
                if(item.start_time<moment(new Date()).format('YYYY-MM-DD HH:mm') && !item.is_closed){
                    return item;
                }
            })
            this.setState({
                all_quiz: quiz
            })
            console.log('live--->>'); 
        }
        else if(this.state.tabName === 'Completed') {
           let quiz = this.state.quizzes.filter(item=>{
                item.start_time =moment(item.start_time).format('YYYY-MM-DD HH:mm')
                console.log('time-->',moment(new Date()).format('YYYY-MM-DD HH:mm'))
                if(item.start_time<moment(new Date()).format('YYYY-MM-DD HH:mm') && item.is_closed){
                    return item;
                }
            })
            this.setState({
                all_quiz: quiz
            })
            console.log('completed--->>'); 
        }
        else{
            let quiz = this.state.quizzes.filter(item=>{
                item.start_time =moment(item.start_time).format('YYYY-MM-DD HH:mm')
                if(item.start_time>moment(new Date()).format('YYYY-MM-DD HH:mm') && !item.is_closed){
                    return item;
                }
            })
            this.setState({
                all_quiz: quiz
            })
            console.log('upcoming--->>'); 
        } 
    })
    }
    updateAll = (e, tab) =>{
       e.preventDefault()
        this.updateTab(tab);
    }
    handleEndQuiz = (e, id) =>{
        e.stopPropagation();
        console.log('ended----')
        scapi('POST', `livequiz/api/v1/cms/endChallenge`, {live_quiz_id: parseInt(id)})
        .then(result=>{
            this.props.history.go(0);
        })
    }
    handleDownloadLeaderboard = (e, id) =>{
        e.stopPropagation();
        console.log('download----')
        // let file = 'text.csv'
        // window.open(file);
        scapi('POST', `livequiz/api/v1/cms/downloadLeaderboard`, {live_quiz_id: parseInt(id)})
        .then(result=>{
            console.log('file---->', result);
            jsonexport(result.leaderboard,function(err, csv){
                if(err) return console.log(err);
                console.log("csv----->", csv);
                const blob = new Blob([csv], {type: 'text/csv'})
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('hidden', '');
                a.setAttribute('href', url);
                a.setAttribute('download', 'leaderboard.csv');
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
            
            // this.props.history.go(0);
        })

    }
    render(){
        return(
            <React.Fragment>
                <section>
                    <div className="lavel">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="title">Live Quizzes</div>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <button className="button is-info" onClick={() => this.handleCreateQuiz(this.state.live_quiz)}>Create Quiz</button>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="tabs is-centered">
                        <ul>
                            { this.state.Tabs.map(tab => {
                                return (<li className={tab.name === this.state.tabName && "is-active"} onClick={(e)=>{this.updateAll(e,tab)}} key={tab.id}><a>{tab.name}</a></li>)
                            })
                            }
                        </ul>
                    </div>
                    <table className="table is-bordered is-fullwidth">
                        <thead>
                            <tr>
                                <th>Quiz Name</th>
                                <th>Quiz Start Time</th>
                                <th>Quiz Description</th>
                                {
                                    this.state.tabName === 'Live'?
                                    (<th>End Quiz</th>)
                                    :  
                                    this.state.tabName === 'Completed'?
                                    (<th>Download Leaderboard</th>)
                                    :
                                    false
                                 }
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.all_quiz.map((quiz, i) => {
                            return <tr onClick={() => this.handleCreateQuiz(quiz)} key={i}>
                                        <td>{quiz.quiz_name}</td>
                                        <td>{quiz.start_time}</td>
                                        <td>{quiz.quiz_description}</td>
                                        {/* <td>
                                            <button className="button is-danger" onClick={()=>this.handleQuizDelete(quiz.id)}>Delete</button>
                                        </td> */}
                                        {
                                            this.state.tabName === 'Live' ? 
                                            (<td><button className="button is-warning" onClick={(e)=>this.handleEndQuiz(e, quiz.id)}>End Quiz</button></td>)
                                            :
                                            this.state.tabName === "Completed"?
                                            (<td><button className="button" onClick={(e)=>this.handleDownloadLeaderboard(e, quiz.id)}>Download</button></td>)
                                            : 
                                            false
                                        }
                                    </tr>
                        })
                              
                         }
                        </tbody>
                    </table>
                </section>  
            </React.Fragment> 
        );
    }
}
export default LiveQuizzes;