import React, {Component, Fragment} from 'react';
import './css/quizRules.css';
import { scapi } from '../utils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css'

class AddOrEditQuizRule extends Component{
    constructor(props){
        super(props)
        this.state = {
            rule: props.location.state.data,
        }
    }
    handleChange = (e) => {
        let {name, value} = e.target;
        let ruleData = {...this.state.rule};
        ruleData[name] = value;
        this.setState({
            rule: ruleData
        })
    }
    handleSubmit = () => {
        let { rule } = this.state;
        let payload = {
            identifier: rule.identifier,
            description: rule.description
        }
        if(rule.id) payload['id'] = rule.id
        scapi('POST', 'livequiz/api/v1/cms/createOrEditQuizRule', payload)
        .then(result => {
            this.props.history.push("/rules");
        })
    }
    // handleT = (value) =>{
    //     console.log('sxsnjns-----', value)
    //     let ruleData = {...this.state.rule};
    //     ruleData.terms_and_condition = value;
    //     this.setState({
    //         rule: ruleData
    //     })
    // }
    render(){
        const { rule} = this.state;
        return(
            <Fragment>
                <div className="title">Add/Edit Quiz Rule</div>
                <div className="field">
                    <label className="label">Identifier</label>
                    <input className="input" name="identifier"  value={rule.identifier} onChange={this.handleChange}></input>
                </div>
                <div className="field">
                    <label className="label">Description</label>
                    <textarea className="textarea is-info" name="description"  value={rule.description} onChange={this.handleChange}></textarea>
                </div>
                {/* <ReactQuill
                    value={rule.terms_and_condition}
                    onChange={this.handleT}
                     /> */}
                <button className="button is-info" onClick={this.handleSubmit}>Submit</button>
            </Fragment>
        )
    }
}
export default AddOrEditQuizRule;