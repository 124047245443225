import React, {Component, Fragment} from 'react';
import moment from 'moment';
import './css/addOrEditLiveQuiz.css'
import { scapi } from '../utils';
import Error from '../component/Error';
import swal from 'sweetalert';
class AddOrEditLiveQuiz extends Component{
    constructor(props){
        super(props)
        this.state =  {
            quiz: props.location.state.data,
            prizes: [],
            rules: [],
            prizeId: [
                {
                    id: null
                }
            ],
            question: {
                question_text: null,
                question_context: null,
                question_option_1: null,
                question_option_2: null,
                question_option_3: null,
                question_option_4: null,
                question_offset: null,
                option_offset: null,
                question_answer: null,
                question_points: 10,
                question_neg_points: 0
            },
            message: null,
            error: null
        }
    }
   async componentDidMount(){

        console.log('data---', this.props.location.state.data);
            if(this.props.location.state.data.id){
            let quiz_data = {...this.state.quiz}
            quiz_data.start_time = moment(this.props.location.state.data.start_time).format('YYYY-MM-DDTHH:mm')
            this.setState({quiz: quiz_data})
            let prize = []
            this.props.location.state.data.id && this.props.location.state.data.quiz_prizes_id[0] && this.props.location.state.data.quiz_prizes_id.forEach(item =>{
                prize.push({id:item});
            })  
            this.setState({
                    prizeId: prize,     
            })
        }
            scapi('GET', 'livequiz/api/v1/cms/allRules')
            .then(rules=>{
                this.setState({
                    rules
                })
            })
            scapi('GET', 'livequiz/api/v1/cms/allPrizes')
            .then(prizes=>{
                this.setState({
                    prizes
                })

            })
        
    }
    handleChange = (e) => {
        e.preventDefault()
        let {name, value} = e.target
        console.log('value--', name, value);
        let quizData = { ...this.state.quiz}
        quizData[name] = value;
        this.setState({
            quiz: quizData,
            error: null
        })
        console.log('quiz state---', this.state.quiz);
    }
    handleSubmit = () => {
        this.setState({error: null})
        let {quiz, prizeId} = this.state;
        let p_id = []
        prizeId.forEach(item=>{
            if(item.id)
                p_id.push(parseInt(item.id));
        })
        quiz.questions.forEach(item =>{
            item.question_answer = parseInt(item.question_answer);
            item.question_offset = parseInt(item.question_offset);
            item.option_offset = parseInt(item.option_offset);
            item.question_points = parseInt(item.question_points);
            item.question_neg_points = parseInt(item.question_neg_points);
            if(item.createdAt) delete item.createdAt
            if(item.updatedAt) delete item.updatedAt
        })
        let question_length = quiz.questions.length;
        if(!quiz.questions[question_length-1].question_text) return this.setState({error: `Please enter the question text in question ${question_length}`});
        if(!quiz.questions[question_length-1].question_option_1) return this.setState({error: `Please enter the question option 1 in question ${question_length}`});
        if(!quiz.questions[question_length-1].question_option_2) return this.setState({error: `Please enter the question option 2 in question ${question_length}`});
        if(!quiz.questions[question_length-1].question_option_3) return this.setState({error: `Please enter the question option 3 in question ${question_length}`});
        if(!quiz.questions[question_length-1].question_option_4) return this.setState({error: `Please enter the question option 4 in question ${question_length}`});
        if(!quiz.questions[question_length-1].question_answer) return this.setState({error: `Please enter the question answer in question ${question_length}`});
        if(!quiz.questions[question_length-1].option_offset) return this.setState({error: `Please enter the option offset in question ${question_length}`});
        if(!quiz.questions[question_length-1].question_offset) return this.setState({error: `Please enter the question offset in question ${question_length}`});
        quiz.quiz_prizes_id = p_id;
        let payload = {
            quiz_name: quiz.quiz_name,
            quiz_description: quiz.quiz_description,
            quiz_rules_id: parseInt(quiz.quiz_rules_id),
            quiz_prizes_id: quiz.quiz_prizes_id,
            start_time:  moment(quiz.start_time, 'YYYY-MM-DDTHH:mm').toISOString(),
            questions: quiz.questions,
            video_link: quiz.video_link
        }
        if(!quiz.quiz_name) return this.setState({error: "Please enter the quiz name"})
        if(!quiz.start_time) return this.setState({error: "Please Set the quiz start time"})
        if(!quiz.quiz_rules_id) return this.setState({error: "Please select the quiz rules"})
        if(!quiz.video_link) return this.setState({error: "Please enter the video link"})
        console.log('payload----', payload);
        if(!this.state.error){
            if(!quiz.id){
                scapi('POST', 'livequiz/api/v1/cms/createQuiz', payload)
                .then(result=>{
                    this.props.history.push("/livequiz");
                })
            }
            else{
                payload['id'] = quiz.id.toString();
                scapi('PUT', '/livequiz/api/v1/cms/editQuiz', payload)
                .then(result=>{
                    this.props.history.push("/livequiz");
                })
            }
        }
    }
    handlePrize = () => {
        let prizeData = [...this.state.prizeId]
        prizeData.push({
            id: null
        });
        this.setState({
            prizeId: prizeData,
            error: null
        })
    }
    handlePrizeChange = (e, i) =>{
        let prizeData = [...this.state.prizeId]
        prizeData[i].id = e.target.value;
        this.setState({
            prizeId: prizeData,
            error: null
        })
    }
    handleQuestionChange = (e, i) =>{
        let {name, value} = e.target;
        let quizData = {...this.state.quiz}
        quizData.questions[i][name] = value
        this.setState({quiz:quizData, error: null});
    }
    handleQuestion = () => {
        let quizData = {...this.state.quiz};
        let questionData = {...this.state.question};
        let length = quizData.questions.length;
        if(quizData.questions[length-1].question_text && 
           quizData.questions[length-1].question_option_1 &&
           quizData.questions[length-1].question_option_2 &&
           quizData.questions[length-1].question_option_3 &&
           quizData.questions[length-1].question_option_4 &&
           quizData.questions[length-1].question_answer &&
           quizData.questions[length-1].question_offset &&
           quizData.questions[length-1].option_offset
           ){
                quizData.questions.push(this.state.question);
                this.setState({quiz:quizData});
                questionData.question_text = null;
                questionData.question_context = null;
                questionData.question_option_1 = null;
                questionData.question_option_2 = null;
                questionData.question_option_3 = null;
                questionData.question_option_4 = null;
                questionData.question_answer = null;
                questionData.question_offset = null;
                questionData.option_offset = null
                this.setState({
                    question: questionData
                })
            }
            else this.setState({error: `Please enter all mandatory fields in question ${length}`});
    }
    handleVideoStream = () => {
        scapi('POST', 'livequiz/api/v1/cms/startVideoStream', {live_quiz_id: this.state.quiz.id})
        .then(result=>{
            console.log(result);
            if(result === 'success') this.setState({message: "You have successfully added this quiz to the video stream"})
        })
    }
    handleResetLeaderboard = () => {
        swal({
            title: "Are you sure?",
            text: "This will Reset the leaderboard",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((proceedWithReset) => {
        if(proceedWithReset){		
            scapi('POST', `livequiz/api/v1/cms/resetLeaderboard`,{
                quiz_id : this.state.quiz.id
            })
            .then( result =>{
                if(result === 'success') this.setState({message: "You have successfully reset the leaderboard"})
            })
        }
        else {
            swal({
                title:"oh!!! Noes",
                text: "Reset Leaderboard Stopped", 
                icon: "error"
            });
        }
    })
    }
    render(){
        const {quiz, rules, prizes, error } = this.state
         const rules_opt =rules && rules.map(rule => {
                            return <option key={rule.id} value={rule.id}>{rule.identifier}</option>
                        })
        const prize_opt =prizes && prizes.map(prize => {
                            return <option key={prize.id} value={prize.id}>{prize.identifier}</option>
                        })
        return(
            <Fragment>
                <div className="lavel">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="title">Add Or Edit live quiz</div>
                            </div>
                        </div>
                       {/* {quiz.id? 
                        <div className="level-right">
                            <div className="level-item">
                                <button className="button is-info" onClick={() => this.handleVideoStream()}>Start Video Stream</button>
                            </div>
                        </div>
                        :
                        <div></div>
                       }   */}
                       {quiz.id? 
                        <div className="level-right">
                            <div className="level-item">
                                <button className="button is-info" onClick={() => this.handleResetLeaderboard()}>Reset Leaderboard</button>
                            </div>
                        </div>
                        :
                        <div></div>
                       } 
                    </div>
                    {this.state.message? 
                    <div>
                        <article className="message is-success">
                            <div className="message-header">
                                <p>Success</p>
                                <button className="delete" aria-label="delete" onClick={()=> this.setState({message: null})}></button>
                            </div>
                            <div className="message-body">
                            {this.state.message}
                            </div>
                        </article>
                    </div>
                    :
                    <div></div>
                    }
                <section>
                    <div className="Field">
                        {quiz.id?(<label className="label">Quiz Id: <span style={{color: "#f15846"}}>{quiz.id}</span></label>)
                        :
                        (<div></div>)
                        }
                    </div>
                    <div className="Field">
                        <label className="label">Quiz Name<span className="required-field">*</span></label>
                        <input className="input" name="quiz_name" value={quiz.quiz_name} type="text" onChange={this.handleChange}></input>
                    </div>
                    <div className="Field">
                        <label className="label">Quiz Description</label>
                        <input className="input" name="quiz_description" value={quiz.quiz_description} type="text" onChange={this.handleChange}></input>
                    </div>
                    <div className="Field">
                        <label className="label">Quiz Start Time<span className="required-field">*</span></label>
                        <input className="input" name="start_time" value={quiz.start_time} type="datetime-local" onChange={this.handleChange}></input>
                    </div>
                    <div className="Field">
                        <label className="label">Video Link<span className="required-field">*</span></label>
                        <input className="input" name="video_link" value={quiz.video_link} type="text" onChange={this.handleChange}></input>
                    </div>
                    <div className="Field">
                        <label className="label">Quiz Rule<span className="required-field">*</span></label>
                        <div className="control">
                            <div className="select is-primary">
                                <select name="quiz_rules_id" value={quiz.quiz_rules_id} onChange={this.handleChange}>
                                    <option selected disabled>Select Rule</option>
                                    {rules_opt}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="Field">
                        {this.state.prizeId.map((prize_id, i)=>{
                             return <div>
                            <label className="label">Quiz Prize {i+1}<span className="required-field">*</span></label>
                           <div className="control">
                            <div className="select is-primary">
                                <select name="id" value={prize_id.id} onChange={e=> this.handlePrizeChange(e,i)}>
                                    <option selected disabled>Select Rule</option>
                                    {prize_opt}
                                </select>
                            </div>
                        </div>
                        </div>
                        })}
                        <button className="button is-small" onClick={this.handlePrize}>Add Prize</button>
                    </div>
                    <div>
                        {this.state.quiz.questions.map((question, i)=>{
                            return <div>
                                    <p className="label">Question {i+1}</p>
                                    <div className="question">
                                    <div className="field">
                                        <label className="label">Question Text<span className="required-field">*</span></label>
                                        <input className="input" name="question_text" type="text" value={question.question_text} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    {/* <div className="field">
                                        <label className="label">Question Context</label>
                                        <input className="input" name="question_context" type="text" value={question.question_context} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div> */}
                                    <div className="field">
                                        <label className="label">Option 1<span className="required-field">*</span></label>
                                        <input className="input" name="question_option_1" type="text" value={question.question_option_1} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    <div className="field">
                                        <label className="label">Option 2<span className="required-field">*</span></label>
                                        <input className="input" name="question_option_2" type="text" value={question.question_option_2} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    <div className="field">
                                        <label className="label">Option 3<span className="required-field">*</span></label>
                                        <input className="input" name="question_option_3" type="text" value={question.question_option_3} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    <div className="field">
                                        <label className="label">Option 4<span className="required-field">*</span></label>
                                        <input className="input" name="question_option_4" type="text" value={question.question_option_4} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    <div className="field">
                                        <label className="label">Question Answer<span className="required-field">*</span></label>
                                        <input className="input" name="question_answer" type="number" value={question.question_answer} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    <div className="field">
                                        <label className="label">Question Points</label>
                                        <input className="input" name="question_points" type="number" value={question.question_points} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    <div className="field">
                                        <label className="label">Question Negative Points</label>
                                        <input className="input" name="question_neg_points" type="number" value={question.question_neg_points} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    <div className="field">
                                        <label className="label">Question Offset(In second)<span className="required-field">*</span></label>
                                        <input className="input" name="question_offset" type="number" value={question.question_offset} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    <div className="field">
                                        <label className="label">Option  Offset(In second)<span className="required-field">*</span></label>
                                        <input className="input" name="option_offset" type="number" value={question.option_offset} onChange={e => this.handleQuestionChange(e, i)}></input>
                                    </div>
                                    </div>
                                </div>
                        })}
                        <button className="button is-small" onClick={this.handleQuestion}>Add Question</button>
                    </div>
                    <button className="button is-info" onClick={this.handleSubmit}>Submit</button>
                </section>
                {error? (<Error value={error}/>)
                    :
                    null
                }
            </Fragment>
        );
    }
}
export default AddOrEditLiveQuiz;