import React from 'react';
import { scapi } from '../utils';
import store from '../store';
import Error from '../component/Error';
class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            password: null,
            savedName: null,
            error: null,
            loader: false
        };
          
    }
    componentDidMount(){

        let name = localStorage.getItem('name')
        this.setState({
            savedName: name
        })
    }

    handleLogin = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        })
    }
    handleSubmit = () => {

        let payload = {
            name: this.state.name,
            password: this.state.password
        }
        this.setState({loader:true})
        scapi('POST', 'livequiz/api/v1/cms/cmsLogin', payload)
        .then(result=>{
            console.log('login--->',result)
            localStorage.setItem('token', result.token)
            localStorage.setItem('name', result.user_name)
            setTimeout(()=>{
                this.props.history.go(0);
            },2000);
        })
        .catch(error=>{
            console.log('error--->', error);
            this.setState({
                error: "Please enter the valid name and password"
            })
        })
    }
    handleLogout = ()=>{
        localStorage.removeItem('token')
        this.props.history.go(0);
    }
    render() {
        const {name, password, savedName, error} = this.state;
        const token = localStorage.getItem('token');

       return (
            <div>
                {error?
                    (<Error value={error} />)
                    :
                    null
                }
                {!token?
                (<div id="login-card" className="card">
                    <div className="card-content">
                        <p className="title">
                        CMS Login
                        </p>
                        <div className="field">
                            <label className="label">Name</label>
                            <input className="input" type="text" name="name" placeholder="Enter Name" value={name} onChange={(e) => this.handleLogin(e)}></input>
                        </div>
                        <div className="field">
                            <label className="label">Password</label>
                            <input className="input" type="password" name="password" placeholder="Enter Password" value={password} onChange={(e) => this.handleLogin(e)}></input>
                        </div>
                        <button className="button is-info" onClick={this.handleSubmit}>Login</button>
                    </div>
                </div>
                )
                :
                (
                    <div className="logout">
                            <p className="subtitle">User: {savedName}</p>
                            <button className="button" onClick={this.handleLogout}>Logout</button>
                    </div>
                )
                }
            </div>
        );
    }
}
export default Login;